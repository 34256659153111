import React, { useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import { Card, CardDeck } from "react-bootstrap";
import moment from "moment";

import "./Dashboard.css";

export default function Dashboard() {

  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    async function onLoad() {

      if (!isAuthenticated) {
        return;
      }

      try {
        const dashboardData = await loadDashboard();
        setDashboardData(dashboardData);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadDashboard() {
    return API.get("NetlogAPI", "/dashboard");
  }

  function renderDevice(device) {

    let style = "danger";
    if (device.last_temp_ts) {
      const ts = moment.unix(device.last_temp_ts / 1000);
      const lastDay = moment().subtract(1, 'days');
      if (ts.isSameOrAfter(lastDay)) {
        style = "info";
      }
    }

    return (
      <Card key={device.device_id} border={style} className="mb-4">
        <Card.Body>
          <Card.Title>
            <b>{device.name}</b>
          </Card.Title>
          <Card.Text>
            Temperature: {device.last_temp_ts ? device.last_temp : "--"} °C<br />

            <span className="small-text" title={moment.unix(device.minimum_temp_ts / 1000).format("YYYY-MM-DD HH:mm:SS")}>
              Min: {device.last_temp_ts ? device.minimum_temp : "--"} °C
            </span>
            <span className="small-text" title={moment.unix(device.maximum_temp_ts / 1000).format("YYYY-MM-DD HH:mm:SS")}>
              Max: {device.last_temp_ts ? device.maximum_temp : "--"} °C
            </span>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">{
          device.last_temp_ts ?
            moment.unix(device.last_temp_ts / 1000).format("YYYY-MM-DD HH:mm:SS") :
            "This device haven't sent reports yet"}</Card.Footer>
      </Card >

    )
  }

  return (
    <div className="Devices">
      <h1>Dashboard</h1>
      <p className="text-muted">Find the latest mesaured values from your devices here. Minimum and maximum values are calculated for the last 24 hours.</p>
      <CardDeck>
        {!isLoading &&
          dashboardData.map((currentDevice) => {
            return (renderDevice(currentDevice));
          }

          )}

      </CardDeck>

    </div>
  );
}