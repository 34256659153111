import React from "react";
import Image from 'react-bootstrap/Image'
import "./Home.css";
import deviceImage from '../images/ttgo_device.jpg'

export default function Home() {
  return (
    <div className="Home">
      <div className="lander">
        <h1>NetLog</h1>
        <p className="text-muted">A simple IoT device logger</p>
        <Image src={deviceImage} alt='A NetLog device' rounded />
      </div>
    </div>
  );
}