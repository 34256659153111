import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table'
import { useParams } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import moment from "moment";

import "./DeviceDetail.css";

export default function DeviceDetail() {

  const { deviceId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState([]);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {

    function loadReport() {
      const url = "/device/" + deviceId + "/last_report";
      return API.get("NetlogAPI", url);
    }

    async function onLoad() {

      if (!isAuthenticated) {
        return;
      }

      try {
        const rep = await loadReport();
        setReport(rep);

      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, deviceId]);

  function getResetReason(resetReason) {
    switch (resetReason) {
      case 0:
        return "Unknown reset";

      case 1:
        return "Power on reset";

      case 2:
        return "External pin reset";

      case 3:
        return "Software triggered reset (esp_reset)";

      case 4:
        return "Software reset due to panic/exception";

      case 5:
        return "Software reset due to interrupt watchdog";

      case 6:
        return "Software reset due to task watchdog";

      case 7:
        return "Software reset due to watchdog";

      case 8:
        return "Exiting deep sleep";

      case 9:
        return "Brownout reset";

      case 10:
        return "Reset due to SDIO";


      default:
        return "Unknown reset code: " + resetReason;
    }
  }

  function getMeasurementUnit(unit) {
    switch (unit) {
      case "celsius":
        return "°C";

      case "fahrenheit":
        return "°F";

      case "volt":
        return "V";

      default:
        return unit;
    }
  }

  function renderReport(report) {

    return (
      <Table key={report.raw_timestamp} striped bordered hover size="sm">

        <tbody>

          <tr>
            <td width="35%">Timestamp:</td>
            <td width="65%">{moment.unix(report.raw_timestamp/1000).format("YYYY-MM-DD HH:mm:SS")}</td>
          </tr>

          <tr>
            <td>App version:</td>
            <td>{report.app_version}</td>
          </tr>

          <tr>
            <td>Connection type:</td>
            <td>{report.networking_mode}</td>
          </tr>

          <tr>
            <td>Restart counter:</td>
            <td>{report.restart_counter}</td>
          </tr>
          <tr>
            <td>Free heap:</td>
            <td>{report.free_heap} bytes</td>
          </tr>
          <tr>
            <td>Last reset reason:</td>
            <td>{getResetReason(report.last_reset_reason)}</td>
          </tr>
          <tr>
            <td>Communication errors:</td>
            <td>{report.comm_error_counter}</td>
          </tr>
          <tr>
            <td>MQTT communication window length:</td>
            <td>{report.mqtt_window_length} seconds</td>
          </tr>
          <tr>
            <td>Deep sleep length:</td>
            <td>{report.deep_sleep_length} seconds</td>
          </tr>


          <tr>
            <td colSpan="2" className="center-text"><b>Measurements</b></td>
          </tr>
          {report.sensors.map((currentSensor) => {
            return (
              <React.Fragment key={currentSensor.sensor_id}>
                <tr>
                  <td rowSpan="2">Sensor id: {currentSensor.sensor_id}</td>
                  <td>{currentSensor.value + " " + getMeasurementUnit(currentSensor.unit)}</td>
                </tr>
                <tr>
                  <td>Error: {currentSensor.error_code !== 0 ? currentSensor.error_code : "None"}</td>
                </tr>
              </React.Fragment>
            )
          })}

          <tr>
            <td>Report Id:</td>
            <td>{report.guid}</td>
          </tr>

        </tbody>
      </Table>

    )
  }

  return (
    <div >
      <div >
        <h1>{deviceId}</h1>
        <p className="text-muted">This is the last received report from the device</p>

        {!isLoading && renderReport(report)}


      </div>

    </div>
  );
}