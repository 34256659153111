import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table'
import { Link } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";

import "./Home.css";

export default function Devices() {

  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    async function onLoad() {

      if (!isAuthenticated) {
        return;
      }

      try {
        const devices = await loadDevices();
        setDevices(devices);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadDevices() {
    return API.get("NetlogAPI", "/devices");
  }

  function renderDeviceList(devices) {
    return (
      <>
        <Table striped bordered hover size="sm">
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Device name</th>
              <th>Device ID</th>
              <th>Last report arrived</th>
            </tr>
          </thead>

          <tbody>
            {devices.map((currentDevice, index) => (

              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <Link to={"/device/" + currentDevice.device_id}>
                    {currentDevice.name}
                  </Link>
                </td>
                <td>{currentDevice.device_id}</td>
                <td>Not implemented yet</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <div className="Devices">
      <h1>My devices</h1>
      <p className="text-muted">This page list all the devices associated to your account</p>
      {!isLoading && renderDeviceList(devices)}
    </div>
  );
}