const dev = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api-dev.netlog.link",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_XcOFrEfgX",
    APP_CLIENT_ID: "2pmdbqbp5mhttsbkpohv7bbpqg",
  },
};

const prod = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api-prod.netlog.link",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_EsXfz0tkB",
    APP_CLIENT_ID: "4koocs71ro5qdvj5s0tndgc1mo",
  },
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default config;